import React from 'react';
import Layout from 'components/Layout';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WidgetContainer from 'controlTower/components/WidgetContainer';
import { widgetsMapping } from 'controlTower/ctConstants';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
// import { byId } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    // top: 0,
    height: '100%',
    // bottom: 0,
    position: 'relative',
    width: '100vw',
  },
  gridContainer: { position: 'relative', height: '100%' },
  gridItem: {
    position: 'relative',
    height: '50%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  widgetContainer: { position: 'relative', height: '100%' },
}));

export default function App(props) {
  const classes = useStyles();

  return (
    <Layout {...props} useNodeSelector>
      <div className={classes.root}>
        <Grid container className={classes.gridContainer}>
          {widgetsMapping.map(({ name, url, appsUrl, widget }, i) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={i} className={classes.gridItem}>
              <WidgetContainer
                name={name}
                url={url}
                appsUrl={appsUrl}
                Widget={widget}
                className={classes.widgetContainer}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Layout>
  );
}

// const theme = useTheme();
// const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

// const widgetSequence = useMemo(() => {
//   if (!isMobileView) return widgetsMapping;
//   return [
//     'Sales Productivity',
//     'Service On Time',
//     'Delivery Planning',
//     'Delivery Execution',
//     'Delivery Failure',
//     'Collection',
//     'Universe',
//   ].map(type => byId(widgetsMapping, type, 'name'));
// }, [isMobileView]);
